









































import { Component, Vue, Prop } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import UploadError from "@/components/UploadError";

@Component({
  components: { UploadError },
  mixins: [formatter],
})
export default class CloseImport extends Vue {
  @Prop() stockId!: number;
  @Prop() companyId!: number;
  closeFile = null;
  uploadPercentage = 0;
  uploadingConfig = false;
  success = false
  successMessage = null
  error = null;
  loading = true;

  created() {
    console.log(this.companyId)
  }

  get canUpload() {
    return (
      this.closeFile !== null &&
      !this.uploadingConfig &&
      this.stockId 
    );
  }

  uploadClose() {
    const state = this.$store.state;

    this.uploadingConfig = true;
    const formData = new FormData();
    const closeUpload = {
      stockId: this.stockId,
      companyId: this.companyId,
      status: "uploading",
      percent: 0,
    };
    console.log(closeUpload)
    // return
    state.closeUploads.push(close);
    formData.append("file", this.closeFile);
    formData.append("stock_id", this.stockId);
    formData.append("company_id", this.companyId);
    // formData.append("sector_id", this.sectorId);
    // formData.append("type", this.typeConfig);

    this.error = null;
    this.$emit("started");
    this.$api
      .post("stock-exchange/upload-company-close", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = state.closeUploads.percent = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
          if (this.uploadPercentage === 100) state.closeUploads.status = "parsing";
        }.bind(this),
      })
      .then((res) => {
        // state.closeUploads.status = "done";
        setTimeout(() => {
          this.$emit("done");
        }, 3000); 
        console.log(res.data)
        this.successMessage = res.data
        this.success = true
        setTimeout(() => {
          state.closeUploads = state.closeUploads.filter(
            cu => cu != state.closeUploads
          );
        }, 1000);
      })
      .catch(e => {
        console.log("ERROR", e);
        this.error = e.response.data;
        state.closeUploads.status = "failed";
        this.uploadingConfig = false;
      })
      .finally(() => {
        this.uploadingConfig = false;
      });
  }
}
